export enum OrganisationActionTypes {
  LOAD_ORGANIZATION_ACTION = 'LoadOrganisation',
  SAVE_ORGANIZATION_ACTION = 'SaveOrganisation',
  DELETE_ORGANIZATION_ACTION = 'DeleteOrganisation',

  TEST_ORGANIZATION_SUPPORT_EMAIL_IMAP_ACTION = 'TestOrganisationSupportEmailIMAP',
  SAVE_ORGANIZATION_SUPPORT_EMAIL_IMAP_ACTION = 'SaveOrganisationSupportEmailIMAP',

  LOAD_USERS_ACTION = 'LoadUsers',
  SET_USERS_PAGE_ACTION = 'SetUsersPage',
  SET_USERS_PAGESIZE_ACTION = 'SetUsersPageSize',
  SET_USERS_TOTAL_ACTION = 'SetUsersTotal',
  SET_USERS_SEARCH_ACTION = 'SetUsersSearch',
  SET_USERS_FILTER_ACTION = 'SetUsersFilter',
  SET_USERS_AVAILABLEFILEDS_ACTION = 'SetUsersAvailableFileds',
  SET_USERS_SORTS_ACTION = 'SetUsersSorts',

  LOAD_GROUPS_ACTION = 'LoadGroups',
  SET_GROUPS_PAGE_ACTION = 'SetGroupsPage',
  SET_GROUPS_PAGESIZE_ACTION = 'SetGroupsPageSize',
  SET_GROUPS_TOTAL_ACTION = 'SetGroupsTotal',
  SET_GROUPS_SEARCH_ACTION = 'SetGroupsSearch',
  SET_GROUPS_FILTER_ACTION = 'SetGroupsFilter',
  SET_GROUPS_AVAILABLEFILEDS_ACTION = 'SetGroupsAvailableFileds',
  SET_GROUPS_SORTS_ACTION = 'SetGroupsSorts',

  LOAD_ROLES_ACTION = 'LoadRoles',
  SET_ROLES_PAGE_ACTION = 'SetRolesPage',
  SET_ROLES_PAGESIZE_ACTION = 'SetRolesPageSize',
  SET_ROLES_TOTAL_ACTION = 'SetRolesTotal',
  SET_ROLES_SEARCH_ACTION = 'SetRolesSearch',
  SET_ROLES_FILTER_ACTION = 'SetRolesFilter',
  SET_ROLES_AVAILABLEFILEDS_ACTION = 'SetRolesAvailableFileds',
  SET_ROLES_SORTS_ACTION = 'SetRolesSorts',

  LOAD_PERMISSIONS_ACTION = 'LoadPermissions',

  LOAD_USER_ACTION = 'LoadUser',
  SAVE_USER_ACTION = 'SaveUser',
  DELETE_USER_ACTION = 'DeleteUser',
  SET_USER_ACTION = 'SetUser',
  LOAD_GROUP_ACTION = 'LoadGroup',
  SAVE_GROUP_ACTION = 'SaveGroup',
  DELETE_GROUP_ACTION = 'DeleteGroup',
  LOAD_ROLE_ACTION = 'LoadRole',
  SAVE_ROLE_ACTION = 'SaveRole',
  DELETE_ROLE_ACTION = 'DeleteRole',

  INVITE_USER_ACTION = 'InviteUser',

  LOAD_ADMIN_ORGANIZATION_ACTION = 'LoadAdminOrganisation',
}
