import { GetterTree } from "vuex";
import { RootState } from "@/store";
import { State } from "./state";
import {
  Organisation,
  User,
  Group,
  Role,
  FieldDef,
  SortDef,
  FilterDef,
  ErrorValidation,
} from "@/types/index";

export type Getters = {
  StateOrganisation(state: State): Organisation | null;
  StateSavingOrganisation(state: State): boolean;
  StateSaveOrganisationError(state: State): string | null;
  StateLoadingOrganisation(state: State): boolean;

  StateDeletingOrganisation(state: State): boolean;
  StateDeleteOrganisationError(state: State): string | null;

  StateTestingOrganisationSupportEmail(state: State): boolean;
  StateTestedOrganisationSupportEmail(state: State): boolean;
  StateTestOrganisationSupportEmailError(state: State): string | null;
  StateTestOrganisationSupportEmailValidationError(state: State): ErrorValidation | null;

  StateSavingOrganisationSupportEmail(state: State): boolean;
  StateSavedOrganisationSupportEmail(state: State): boolean;
  StateSaveOrganisationSupportEmailError(state: State): string | null;
  StateSaveOrganisationSupportEmailValidationError(state: State): ErrorValidation | null;

  StateUsers(state: State): User[];
  StateLoadingUsers(state: State): boolean;
  StateUsersLoaded(state: State): boolean;

  StateUsersPage(state: State): number;
  StateUsersPageSize(state: State): number;
  StateUsersTotal(state: State): number;
  StateUsersSearch(state: State): string | null;
  StateUsersFilter(state: State): FilterDef[];
  StateUsersAvailableFileds(state: State): FieldDef[];
  StateUsersSorts(state: State): SortDef[];

  StateGroups(state: State): Group[];
  StateLoadingGroups(state: State): boolean;

  StateGroupsPage(state: State): number;
  StateGroupsPageSize(state: State): number;
  StateGroupsTotal(state: State): number;
  StateGroupsSearch(state: State): string | null;
  StateGroupsFilter(state: State): FilterDef[];
  StateGroupsAvailableFileds(state: State): FieldDef[];
  StateGroupsSorts(state: State): SortDef[];

  StateRoles(state: State): Role[];
  StateLoadingRoles(state: State): boolean;
  StateRolesLoaded(state: State): boolean;

  StateRolesPage(state: State): number;
  StateRolesPageSize(state: State): number;
  StateRolesTotal(state: State): number;
  StateRolesSearch(state: State): string | null;
  StateRolesFilter(state: State): FilterDef[];
  StateRolesAvailableFileds(state: State): FieldDef[];
  StateRolesSorts(state: State): SortDef[];

  StateLoadingRoles(state: State): boolean;
  StateCrudFocusedUser(state: State): User | null;
  StateLoadingCrudFocusedUser(state: State): boolean;
  StateSavingCrudFocusedUser(state: State): boolean;
  StateCreatingCrudFocusedUser(state: State): boolean;
  StateCreatingCrudFocusedUserSucceeded(state: State): boolean;
  StateCreatingCrudFocusedUserData(state: State): string | null;
  StateDeletingCrudFocusedUser(state: State): boolean;
  StateDeletedCrudFocusedUserSucceeded(state: State): boolean;
  StateDeletedCrudFocusedUserData(state: State): string | null;
  StateCrudFocusedGroup(state: State): Group | null;
  StateLoadingCrudFocusedGroup(state: State): boolean;
  StateSavingCrudFocusedGroup(state: State): boolean;
  StateCrudFocusedRole(state: State): Role | null;
  StateLoadingCrudFocusedRole(state: State): boolean;
  StateSavingCrudFocusedRole(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  StateOrganisation: (state) => state.organisation,
  StateSavingOrganisation: (state) => state.savingOrganisation,
  StateSaveOrganisationError: (state) => state.saveOrganisationError,
  StateLoadingOrganisation: (state) => state.loadingOrganisation,

  StateDeletingOrganisation: (state) => state.deletingOrganisation,
  StateDeleteOrganisationError: (state) => state.deleteOrganisationError,

  StateTestingOrganisationSupportEmail: (state) => state.testingOrganisationSupportEmail,
  StateTestedOrganisationSupportEmail: (state) => state.testedOrganisationSupportEmail,
  StateTestOrganisationSupportEmailError: (state) => state.testOrganisationSupportEmailError,
  StateTestOrganisationSupportEmailValidationError: (state) => state.testOrganisationSupportEmailValidationError,

  StateSavingOrganisationSupportEmail: (state) => state.savingOrganisationSupportEmail,
  StateSavedOrganisationSupportEmail: (state) => state.savedOrganisationSupportEmail,
  StateSaveOrganisationSupportEmailError: (state) => state.saveOrganisationSupportEmailError,
  StateSaveOrganisationSupportEmailValidationError: (state) => state.saveOrganisationSupportEmailValidationError,

  StateUsers: (state) => state.users,
  StateLoadingUsers: (state) => state.loadingUsers,
  StateUsersLoaded: (state) => state.usersLoaded,

  StateUsersPage: (state) => state.usersPage,
  StateUsersPageSize: (state) => state.usersPageSize,
  StateUsersTotal: (state) => state.usersTotal,
  StateUsersSearch: (state) => state.usersSearch,
  StateUsersFilter: (state) => state.usersFilter,
  StateUsersAvailableFileds: (state) => state.usersAvailableFileds,
  StateUsersSorts: (state) => state.usersSorts,

  StateGroups: (state) => state.groups,
  StateLoadingGroups: (state) => state.loadingGroups,

  StateGroupsPage: (state) => state.groupsPage,
  StateGroupsPageSize: (state) => state.groupsPageSize,
  StateGroupsTotal: (state) => state.groupsTotal,
  StateGroupsSearch: (state) => state.groupsSearch,
  StateGroupsFilter: (state) => state.groupsFilter,
  StateGroupsAvailableFileds: (state) => state.groupsAvailableFileds,
  StateGroupsSorts: (state) => state.groupsSorts,

  StateRoles: (state) => state.roles,
  StateLoadingRoles: (state) => state.loadingRoles,
  StateRolesLoaded: (state) => state.rolesLoaded,

  StateRolesPage: (state) => state.rolesPage,
  StateRolesPageSize: (state) => state.rolesPageSize,
  StateRolesTotal: (state) => state.rolesTotal,
  StateRolesSearch: (state) => state.rolesSearch,
  StateRolesFilter: (state) => state.rolesFilter,
  StateRolesAvailableFileds: (state) => state.rolesAvailableFileds,
  StateRolesSorts: (state) => state.rolesSorts,

  StatePermissions: (state) => state.permissions,
  StateLoadingPermissions: (state) => state.loadingPermissions,

  StateCrudFocusedUser: (state) => state.crudFocusedUser,
  StateLoadingCrudFocusedUser: (state) => state.loadingCrudFocusedUser,
  StateSavingCrudFocusedUser: (state) => state.savingCrudFocusedUser,
  StateCreatingCrudFocusedUser: (state) => state.creatingCrudFocusedUser,
  StateCreatingCrudFocusedUserSucceeded: (state) => state.creatingCrudFocusedUserSucceeded,
  StateCreatingCrudFocusedUserData: (state) => state.creatingCrudFocusedUserData,
  StateDeletingCrudFocusedUser: (state) => state.deletingCrudFocusedUser,
  StateDeletedCrudFocusedUserSucceeded: (state) => state.deletedCrudFocusedUserSucceeded,
  StateDeletedCrudFocusedUserData: (state) => state.deletedCrudFocusedUserData,

  StateCrudFocusedGroup: (state) => state.crudFocusedGroup,
  StateLoadingCrudFocusedGroup: (state) => state.loadingCrudFocusedGroup,
  StateSavingCrudFocusedGroup: (state) => state.savingCrudFocusedGroup,

  StateCrudFocusedRole: (state) => state.crudFocusedRole,
  StateLoadingCrudFocusedRole: (state) => state.loadingCrudFocusedRole,
  StateSavingCrudFocusedRole: (state) => state.savingCrudFocusedRole,

  StateInviteUserError: (state) => state.inviteUserError,
  StateInviteUserErrorMessage: (state) => state.inviteUserErrorMessage,
};
